@font-face {
  font-family: 'MessinaSansWeb';
  font-stretch: normal;
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/MessinaSansWeb-Regular.eot'); /* IE9 Compat Modes */
  src: url('../fonts/MessinaSansWeb-Regular.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/MessinaSansWeb-Regular.woff2') format('woff2'),
    url('../fonts/MessinaSansWeb-Regular.woff') format('woff'); /* Pretty Modern Browsers */
}
@font-face {
  font-family: 'MessinaSansWeb';
  font-stretch: normal;
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/MessinaSansWeb-Bold.eot'); /* IE9 Compat Modes */
  src: url('../fonts/MessinaSansWeb-Bold.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/MessinaSansWeb-Bold.woff2') format('woff2'),
    url('../fonts/MessinaSansWeb-Regular.woff') format('woff'); /* Pretty Modern Browsers */
}
@font-face {
  font-family: 'MessinaSansWeb';
  font-stretch: normal;
  font-style: italic;
  font-weight: 400;
  src: url('../fonts/MessinaSansWeb-RegularItalic.eot'); /* IE9 Compat Modes */
  src: url('../fonts/MessinaSansWeb-RegularItalic.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/MessinaSansWeb-RegularItalic.woff2') format('woff2'),
    url('../fonts/MessinaSansWeb-RegularItalic.woff') format('woff'); /* Pretty Modern Browsers */
}
@font-face {
  font-family: 'MessinaSansWeb';
  font-stretch: normal;
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/MessinaSansWeb-Light.eot'); /* IE9 Compat Modes */
  src: url('../fonts/MessinaSansWeb-Light.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/MessinaSansWeb-Light.woff2') format('woff2'),
    url('../fonts/MessinaSansWeb-Regular.woff') format('woff'); /* Pretty Modern Browsers */
}

@font-face {
  font-family: 'MessinaSansWebCondensed';
  font-stretch: condensed;
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/MessinaSansCondensedWeb-Light.eot'); /* IE9 Compat Modes */
  src: url('../fonts/MessinaSansCondensedWeb-Light.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/MessinaSansCondensedWeb-Light.woff2') format('woff2'),
    url('../fonts/MessinaSansCondensedWeb-Light.woff') format('woff'); /* Pretty Modern Browsers */
}
