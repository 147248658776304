.pick-role-container {
  .pick-role-parent {
    .pick-role-wrapper {
      .boxes {
        border-bottom: 1px solid $color--black;
      }
    }

    @include respond-to('m') {
      padding: 0;
      .pick-role-wrapper {
        padding: 0;
      }
    }
    @media (max-width: 1350px) {
      .col-lg-3.col-6 {
        min-width: 50%;
      }
    }
  }
}
.projectCategory-container {
  @media (max-width: 1350px) {
    .row {
      .col-lg-3.col-6 {
        min-width: 50%;
      }
    }
  }
}
