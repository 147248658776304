.uploadInspiration-container {
  .pictures {
    .singlepic-upload {
      margin-bottom: 30px;
      .image {
        width: 100%;
        height: 39rem;
        overflow: hidden;
        img {
          max-width: 100%;
          // border-radius: 5px;
          // width: 100%;
          border-radius: 5px;

          height: 100%;
          width: 100%;
          margin: 0 auto;
          float: none;
          display: block;
          object-fit: cover;
        }
      }
      .title {
        border-bottom: 1px solid $color--black;
        padding-top: 2.5rem;
        padding-bottom: 10px;
        word-break: break-all;
      }
      .delete-file {
        cursor: pointer;
        background: $color--orange;
        border: 1px solid $color--orange;
        border-radius: 5px;
        width: 20px;
        height: 20px;
        text-align: center;
        position: absolute;
        right: 0;
        z-index: 3;
        margin: 10px 22px 0 0;
        @include animation(0.3s);
        padding: 0;
        svg {
          max-width: 10px;
          margin-top: -8px;
          path {
            stroke: $color--white;
            @include animation(0.3s);
          }
        }

        &:hover {
          background: $color--white;
          svg {
            path {
              stroke: $color--orange;
            }
          }
        }
      }
    }
  }

  @include respond-to('m') {
    .uploadInspiration-info {
      .form-item {
        padding: 0;
        max-width: 100%;
        width: 100%;
        label {
          font-size: 15px;
        }
      }
    }
    .pictures {
      .singlepic-upload {
        .image {
          height: auto;
          img {
            height: auto;
          }
        }
      }
    }
  }
}
