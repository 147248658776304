$breakpoints: (
  's': (
    max-width: 576px,
  ),
  'm': (
    max-width: 768px,
  ),
  'l': (
    min-width: 992px,
  ),
  'xl': (
    min-width: 1200px,
  ),
);

@mixin respond-to($breakpoint) {
  $raw-query: map-get($breakpoints, $breakpoint);

  @if $raw-query {
    $query: if(
      type-of($raw-query) == 'string',
      unquote($raw-query),
      inspect($raw-query)
    );

    @media #{$query} {
      @content;
    }
  } @else {
    @error 'No value found for `#{$breakpoint}`. '
      + 'Please make sure it is defined in `$breakpoints` map.';
  }
}

@media (max-width: 1200px) {
  .wall-to-wall-wrapper {
    margin-top: 30px;
  }
  .area-rug-wrapper {
    .p-mob-0 {
      padding: 0;
    }
    .form-item {
      select {
        float: left;
        margin-top: 10px;
      }
    }
  }
}

@media (min-width: 770px) and (max-width: 1200px) {
  .sidebar-profile {
    display: block;
    .profile-image-wrapper {
      margin-bottom: 10px;
      margin-left: 0;
      padding-left: 0;
    }
    .selected-options {
      padding-left: 0;
      .price-calculations {
        padding: 0;
      }
      .content {
        position: relative;
        .infobox-wrapper {
          position: absolute;
          top: 0;
          right: 0;
        }
      }
    }
    .personal-details {
      min-width: 100%;
      .phone-mail {
        padding-left: 0;
      }
    }
  }
  #root .content {
    max-width: 70%;
  }
  #root #sidebar-container {
    min-width: 30%;
    width: 30%;
  }
  #root .header-logo {
    padding-left: 4rem;
  }
  #root .startpage-container.leftside-content {
    padding-left: 0;
  }
}

@media (max-width: 1000px) {
  .colorbox-brief-wrapper {
    padding: 0;
  }
}

@media (min-width: 990px) and (max-width: 1010px) {
  .form .datepicker-row svg {
    right: 15px;
  }
}

@media (max-width: 780px) {
  .checkout-page,
  .billingaddress-container {
    .form input:focus + label,
    .form .field-filled label {
      top: 0;
    }
  }
  .area-specification-details {
    .form {
      input,
      select,
      textarea,
      .react-datepicker-wrapper,
      .form-item {
        min-width: 100% !important;
        width: 100%;
      }
    }
  }
}

@include respond-to('m') {
  .hide-mob {
    display: none !important;
  }
  .content {
    .mobile-menu {
      padding: 1rem 0;
      background-color: rgb(31 29 26 / 0.03);
      text-align: center;
      .mobile-menu-wrapper {
        text-align: left;
        width: auto;
        margin: 0 auto;
        float: none;
        display: inline-block;
        .mobile-menu-title {
          padding-top: 13px;
          padding-right: 0;
          padding-left: 0;
        }
        .information {
          float: left;
          padding: 0;
          display: inline-block;
          width: auto;
          .arrow-icon {
            @include animation(0.3s);
            float: left;
            transform: rotate(0deg) translateY(0px);
          }
          .price-total-wrapper {
            float: left;
            .price-calculations {
              .shipping-info,
              .product-selection-row {
                display: none;
              }
              .total-price {
                line-height: 1.7;
                .from {
                  display: none;
                }
              }
            }
          }
          .price-calculations {
            .currency-selector {
              display: none;
            }
          }
        }
      }
      &.active {
        .information {
          .arrow-icon {
            transform: rotate(180deg) translateY(-2px);
          }
        }
      }
    }
  }

  .p-mob-0 {
    padding: 0;
  }
  .startpage-container {
    margin-top: 5rem;
  }

  .sidebar-profile {
    display: block;
    .profile-image-wrapper {
      float: left;
    }
    .personal-details {
      margin: 0;
      width: 100%;
      min-width: 100%;
      margin-bottom: 40px;
      padding-top: 30px;
      .phone-mail {
        padding-left: 0;
      }
    }
    .selected-options {
      float: left;
      width: 100%;
      margin-top: 30px;
    }
    .bottom-buttons {
      display: none;
    }
  }

  .sidebar-profile {
    .price-calculations {
      padding-left: 0;
    }
    .profile-image-wrapper {
      img {
        margin: 0 auto;
        float: none;
        display: block;
        max-height: 100px;
      }
    }
  }
  .content.sidebar-opened {
    height: 150px !important;
    overflow: hidden;
    min-height: 150px !important;
  }
  .container-buttons {
    padding: 0;
    min-width: 100%;
    .next-btn button.btn {
      float: right;
    }
  }
  .row-card {
    &.card-upload {
      .header {
        height: 69%;
      }
    }
  }

  .billingaddress-container {
    .custom-switch {
      label[for='id_fieldWork'] {
        font-size: 13px;
      }
    }
  }

  input {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
  }
}

@include respond-to('s') {
  form.form input:focus + label,
  form.form .field-filled label {
    font-size: 10px;
    top: 5px;
  }
  form.form input:not([type='checkbox']):focus,
  form.form input:not([type='checkbox']) {
    padding-top: 18px;
  }
  .sidebar-profile {
    .personal-details {
      padding-top: 0px;
      .phone-mail {
        padding-left: 0;
      }
    }
  }
}
