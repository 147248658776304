@mixin animation($speed) {
  transition: all $speed ease-in-out;
  transition: all $speed ease-in-out;
  transition: all $speed ease-in-out;
}

@mixin button-anim($color) {
  &:not(.disabled) {
    span.anim {
      float: left;
      overflow: hidden;
      position: relative;

      &::before {
        @include animation(0.4s);
        background-color: $color;
        bottom: 0;
        content: '';
        height: 1px;
        left: -100%;
        position: absolute;
        visibility: hidden;
        width: 100%;
      }
    }

    &:hover {
      span.anim {
        &::before {
          animation: underline 1.5s infinite;
          // left: 15%;
          visibility: visible;
          width: 100%;
        }
      }
    }
  }
}

@keyframes underline {
  5% {
    width: 100%;
    left: 0;
  }
  50% {
    left: 100%;
    width: 0;
  }
  51% {
    left: 0;
    width: 0;
  }
  100% {
    left: 0;
    width: 100%;
  }
}
@mixin link-anim($color) {
  &:not(.disabled) {
    overflow: hidden;
    position: relative;

    &::before {
      @include animation(0.4s);
      background-color: $color;
      bottom: 0;
      content: '';
      height: 1px;
      left: 0%;
      position: absolute;
      visibility: visible;
      width: 100%;
    }

    &:hover {
      &::before {
        // width: 100%;
        // left:75%;
        animation: underline 1.5s infinite;
        visibility: visible;
      }
    }
  }
}
@keyframes link-anim-move {
  from {
    left: -100%;
  }
  to {
    left: 50%;
  }
}

@keyframes link-anim-move-back {
  from {
    left: 50%;
  }
  to {
    left: 0;
  }
}

@mixin borderBottom-anim($color) {
  label {
    overflow: hidden;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      width: 100%;
      border-bottom: 1px solid $color;
      left: -100%;

      @include animation(0.4s);
    }
  }
  &:hover {
    label {
      &::before {
        animation: underline 1.5s infinite;
        visibility: visible;
      }
    }
  }
}

@keyframes label-anim-move {
  from {
    left: -100%;
  }
  to {
    left: 0%;
  }
}

@keyframes label-anim-move-back {
  from {
    left: 50%;
  }
  to {
    left: -20;
  }
}

// upload circle
.spinner {
  -webkit-animation: rotator 1.4s linear infinite;
  animation: rotator 1.4s linear infinite;
}

@-webkit-keyframes rotator {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(270deg);
    transform: rotate(270deg);
  }
}

@keyframes rotator {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(270deg);
    transform: rotate(270deg);
  }
}
.path {
  stroke-dasharray: 187;
  stroke-dashoffset: 0;
  -webkit-transform-origin: center;
  -ms-transform-origin: center;
  transform-origin: center;
  stroke: $color--orange;
  stroke-width: 3px;
  -webkit-animation: dash 1.4s ease-in-out infinite;
  animation: dash 1.4s ease-in-out infinite;
}

@-webkit-keyframes dash {
  0% {
    stroke-dashoffset: 187;
  }
  50% {
    stroke-dashoffset: 46.75;
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
  }
  100% {
    stroke-dashoffset: 187;
    -webkit-transform: rotate(450deg);
    transform: rotate(450deg);
  }
}
@keyframes dash {
  0% {
    stroke-dashoffset: 187;
  }
  50% {
    stroke-dashoffset: 46.75;
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
  }
  100% {
    stroke-dashoffset: 187;
    -webkit-transform: rotate(450deg);
    transform: rotate(450deg);
  }
}

.loading {
  display: flex;
  justify-content: center;
  margin: 0 auto;

  div {
    width: 1rem;
    height: 1rem;
    margin: 2rem 0.3rem;
    background: $color--orange;
    border-radius: 50%;
    animation: 0.9s bounce infinite alternate;

    &:nth-child(2) {
      animation-delay: 0.3s;
    }

    &:nth-child(3) {
      animation-delay: 0.6s;
    }
  }
}

@keyframes bounce {
  to {
    opacity: 0.3;
    transform: translate3d(0, -1rem, 0);
  }
}
