.colorSelective-container {
  .color-list {
    .col {
      min-width: 25%;
      margin-bottom: 5rem;
      .picture-selection {
        border-radius: 1rem;
        border-radius: 5px;
        img {
          min-height: 45rem;
          opacity: 0;
          @include respond-to('m') {
            min-height: 20rem;
          }
        }
      }
    }
  }
}
