.sidebar {
  background-color: rgb(31 29 26 / 0.03);
  padding: 3rem 3rem 0 3rem;
  .selected-options {
    // margin-top: 5rem;
    ul {
      padding: 0;
      li {
        list-style-type: none;
        float: left;
        width: 50%;
        min-height: 5rem;
        margin-bottom: 1rem;
        .header {
          opacity: 0.5;
          font-weight: 300;
          font-stretch: condensed;
          font-style: normal;
          line-height: 1.3;
          letter-spacing: 1.6px;
          text-align: left;
          float: left;
          width: 100%;
          font-size: 1.1rem;
        }
        .content {
          font-weight: 300;
          font-stretch: normal;
          font-style: normal;
          letter-spacing: normal;
          text-align: left;
          font-size: 1.3rem;
          .content_text {
            float: left;
            margin-right: 1rem;
          }
        }
      }
    }
  }
  .proceed-without-product {
    float: right;
    &:hover {
      color: $color--orange;
    }
  }
  @include respond-to('m') {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    visibility: hidden;
    z-index: -3;
    background-color: #f8f8f7;
    @include animation(0.3s);
    &.active {
      opacity: 1;
      z-index: 99999;
      visibility: visible;
      top: 7rem;
      padding-top: 5px;
    }
  }
}
