.infobox-wrapper {
  @include animation(0.3s);
  float: left;
  .infobox {
    position: relative;
    float: left;
    margin-top: -4px;
  }
  .hovered-information {
    display: none;
    position: absolute;
    padding: 0.5rem 1.4rem 0.5rem 1rem;
    border-radius: 5px;
    background-color: $color--blue;
    color: $color--white;
    margin-left: 2rem;

    font-weight: 300;
    font-stretch: condensed;
    font-style: normal;
    line-height: 1.3;
    letter-spacing: normal;
    text-align: left;
    z-index: 3;
    @include animation(0.3s);
  }
  svg {
    g,
    path {
      @include animation(0.3s);
    }
  }
  &:hover {
    .hovered-information {
      display: block;
      margin-top: 5px;
    }
    svg {
      g {
        stroke: $color--orange;
      }
      path {
        fill: $color--orange;
      }
    }
  }
}
