$color--white: #fff;
$color--black: #1f1d1a;
$color--grey: #1f1d1a;
$color--orange: #cc7828;
$color--blue: #4f7da0;
$color--danger: red;

.blackc {
  color: $color--black !important;
}
.orangec {
  color: $color--orange !important;
}
.whitec {
  color: $color--white !important;
}
