.content {
  .startPage-buttons {
    .btn {
      &:first-child {
        margin-right: 1.5rem;
      }
    }
  }

  .leftside-content {
    padding-left: 8rem;

    @include respond-to('m') {
      padding: 1rem;
      padding-top: 0;
      .logo {
        padding: 2rem;
      }
      .startPage-buttons {
        float: left;
        width: 100%;
        padding: 0;
        margin: 0;
        button {
          float: left;
          width: 80%;
          margin: 0 auto;
          float: none;
          display: block;
          margin-left: auto !important;
          margin-right: auto !important;
          margin-bottom: 20px;
        }
      }
    }
  }
}
