input,
textarea,
select {
  outline: 0;
}

.form {
  .custom-select:focus {
    border-color: $color--black;
  }
  input:not([type='checkbox']) {
    background: transparent;
    border: 0;
    border-bottom: 2px solid $color--black;
    color: $color--black;
    display: block;

    font-size: 1.6rem;
    font-stretch: normal;
    font-style: normal;
    font-weight: 300;
    letter-spacing: normal;
    line-height: 1.75;
    min-width: 43rem;
    outline-color: transparent;
    padding: 1rem 0.1rem;
    position: relative;
    text-align: left;
    transition: all 0.3s ease;
    z-index: 5;

    &:focus {
      border: 0;
      border-bottom: 2px solid $color--black;
    }
    @include respond-to('m') {
      min-width: 100%;
    }
  }

  input[type='checkbox'] {
    display: none;
    &:checked + label:before {
      background-color: $color--orange;
      border-color: $color--orange;
      color: #fff;
    }
  }
  input[type='checkbox'] + label {
    display: block;
    margin: 2px;
    cursor: pointer;
    padding: 2px;
    &:before {
      content: '\2714';
      border: 0.1em solid #000;
      border-radius: 2px;
      display: inline-block;
      width: 10px;
      height: 10px;
      margin-right: 2px;
      vertical-align: bottom;
      color: transparent;
      transition: 0.2s;
    }
  }
  &:active:before {
    transform: scale(0);
  }

  label:not(.checkbox-label) {
    color: $color--black;
    cursor: text;
    font-size: 2rem;
    font-stretch: condensed;
    font-style: normal;
    font-weight: 300;
    left: 0;
    letter-spacing: normal;
    line-height: 0.8;
    position: absolute;
    text-align: left;
    top: 2rem;
    transition: all 0.3s ease;
    z-index: 3;
    opacity: 0.4;
  }

  input:focus + label,
  .field-filled label {
    font-size: 1rem;
    font-weight: 300;
    top: -5px;
    z-index: 55;
  }

  .invalid-field-warning {
    color: $color--orange !important;
    font-size: 1.2rem;
    font-stretch: condensed;
    font-style: normal;
    font-weight: 300;
    letter-spacing: normal;
    line-height: 1.2;
    margin-top: 0.5rem;
    text-align: left;
  }

  .invalid-field,
  .invalid-field:focus {
    input {
      border-color: $color--orange !important;
      color: $color--orange;
    }
  }

  .radio-wrapper {
    float: left;
    width: 100%;
    input {
      float: left;
      min-width: 2rem;
      padding: 0;
      margin: 0;
    }
    label {
      float: left;
      width: auto;
      position: relative;
      top: 0 !important;
      color: $color--black;
      opacity: 1 !important;
      font-weight: 500 !important;
      font-size: 2rem !important;
    }
  }

  input[type='radio'] {
    &:checked,
    &:not(:checked) {
      position: absolute;
      left: -9999px;
    }
    &:checked + label,
    &:not(:checked) + label {
      position: relative;
      padding-left: 2.8rem;
      cursor: pointer;
      line-height: 2rem;
      display: inline-block;
      color: #666;
    }
    &:not(:checked) + label:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 18px;
      height: 18px;
      border: 1px solid #ddd;
      border-radius: 100%;
      background: #fff;
    }
    &:checked + label:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 18px;
      height: 18px;
      border-radius: 100%;
      background: #fff;
      border: 1px solid $color--orange;
    }
    &:checked + label:after,
    &:not(:checked) + label:after {
      content: '';
      width: 12px;
      height: 12px;
      background: $color--orange;
      border: 1px solid $color--orange;
      position: absolute;
      top: 3px;
      left: 3px;
      border-radius: 100%;
      -webkit-transition: all 0.2s ease;
      transition: all 0.2s ease;
    }
    &:not(:checked) + label:after {
      opacity: 0;
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    &:checked + label:after {
      opacity: 1;
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }

  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding: 0.2rem 0.2rem 0.2rem 2rem;
    border: none;
    background: transparent url('../../images/select-arrow.svg') no-repeat right
      center;
    border-bottom: 2px solid $color--orange;

    border: 0;
    border-bottom: 2px solid $color--black;
    color: $color--black;
    display: block;
    position: relative;
    text-align: left;
    transition: all 0.3s ease;
    z-index: 5;
    padding-left: 0;

    font-size: 1.6rem;
    font-stretch: normal;
    font-style: normal;
    font-weight: 300;
    letter-spacing: normal;
    line-height: 1.75;

    &:focus {
      outline-color: transparent;
      box-shadow: none;
    }
  }
  .form-description {
    font-size: 2rem;
    font-weight: 300;
    font-style: normal;
    line-height: 1.75;
    text-align: left;
  }

  textarea {
    height: 0;
    border: 0;
    min-width: 43rem;
    border-bottom: 2px solid $color--black;
    z-index: 55;
    position: relative;
    background: transparent;
    padding-top: 2rem;
    padding-bottom: 0;
    min-height: 5rem;
    font-weight: 300;
    &:focus {
      outline-color: transparent;
      border: 0;
      border-bottom: 2px solid $color--black;
    }
  }

  .datepicker-row {
    .react-datepicker-popper {
      z-index: 90;
    }
    .react-datepicker__tab-loop {
      position: absolute;
      top: 0;
    }
    .react-datepicker__day {
      width: 2rem;
      line-height: 1.8;
      margin: 1.2rem;
      font-size: 1.5rem;
    }
    .react-datepicker__current-month {
      font-size: 2rem;
      margin-bottom: 1.5rem;
    }
    .react-datepicker__day-name {
      font-size: 1.6rem;
      margin: 0 1.3rem;
      margin-bottom: 1rem;
    }
    .react-datepicker__navigation {
      border-width: 0.8rem;
    }
    .react-datepicker__day--keyboard-selected,
    .react-datepicker__day--selected {
      background: transparent;
      color: $color--orange;
      font-size: 2rem;
      position: relative;
      top: 2px;
    }
    svg {
      @include animation(0.3s);
      position: absolute;
      top: 2rem;
      margin-left: -3rem;
      .cls-1 {
        stroke: $color--black;
        @include animation(0.3s);
      }
    }
    &:hover {
      svg {
        .cls-1 {
          stroke: $color--orange;
        }
        text {
          fill: $color--orange;
        }
      }
    }

    @include respond-to('m') {
      svg {
        right: 0;
      }
    }
  }
}

.form-item {
  margin-bottom: 1.5rem;
  position: relative;
  @include respond-to('m') {
    width: 80%;
  }

  &.with-sqm,
  &.with-cm {
    &:after {
      content: '';
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      color: $color--grey;
    }
  }

  &.with-sqm {
    &:after {
      content: 'sqm';
    }
  }

  &.with-cm {
    &:after {
      content: 'cm';
    }
  }
}

.custom-switch {
  label.custom-control-label {
    color: $color--black;
    font-size: 20px;
    font-stretch: normal;
    font-style: normal;
    font-weight: 500;
    letter-spacing: normal;
    line-height: 1.4;
    opacity: 1;
    padding-left: 50px !important;
    padding-top: 2px;
    position: absolute;
    text-align: left;
    top: 0;
  }

  input[type='checkbox'] + label::before {
    content: '';
    border: solid 2px $color--grey;
    // background-color: transparent;
    // height: 25px;
    // margin: 2px 24px 1px 0;
    // padding: 2.5px 2.5px 2.5px 25px;
    // width: 55px;
    // border: solid 2px $color--black;
    // height: 25px;
    // width: 55px;
    // border-radius: 50px;

    background-color: transparent;
    height: 23px;
    width: 55px;
    border-radius: 50px;
  }

  input[type='checkbox'] + label::after {
    // background-color: transparent;
    // border: solid 2px $color--black;
    // border-radius: 28px;
    // height: 20px;
    // top: calc(1px + 3.3px);
    // left: calc(-2rem + 2px);
    // width: 20px;
    // @include respond-to('m') {
    //   left: calc(-17px + 2px);
    // }

    position: absolute;
    top: 5px;
    width: 17px;
    height: 17px;
    border-radius: 50%;
    border: 2px solid;
    background: transparent;
    left: -19px;

    @media (max-width: 2580px) {
      left: -18px;
      top: 6px;
    }
    @media (max-width: 1900px) {
      left: -14px;
      top: 5px;
    }
  }

  .custom-control-input {
    position: absolute;
    &:checked {
      & ~ .custom-control-label {
        &::before {
          background-color: $color--orange;
          border-color: transparent;
        }

        &::after {
          transform: translateX(29px);
          background: transparent;
          border-color: #fff;
          // background-color: $color--orange;
          // border: solid 2px $color--white;
          // transform: translateX(2.8rem) translateY(1px);
        }
      }
    }
  }
}

.upload-row {
  .form-item {
    cursor: pointer;
    border-bottom: 2px solid $color--black;
    float: left;
    overflow: hidden;
    width: 100%;
    float: left;
    .upload-ico {
      position: absolute;
      right: 0;
      top: 10px;
      @include animation(0.3s);
      svg {
        text {
          @include animation(0.3s);
        }
      }
      .spinner {
        width: 20px;
        position: relative;
        top: -20px;
      }
    }
    input {
      border-bottom: 2px solid;
      cursor: pointer;
      float: left;
      position: relative;
      width: 100%;
      opacity: 0;

      label {
        cursor: pointer;
        font-size: 1.6rem;
        margin-top: 1rem;

        &.active {
          font-size: 1.2rem;
          margin-top: -5px;
          top: 2rem;
        }
      }
      &:focus + label {
        top: 10px;
      }

      p.file_name {
        bottom: -9px;
        cursor: pointer;
        height: 2.6rem;
        overflow: hidden;
        position: absolute;
        width: 100%;
      }

      svg {
        height: 7rem;
        padding: 18px 0;
        position: absolute;
        right: 0;
        top: -5px;

        &.remove-ico {
          padding-top: 3.5rem;
          z-index: 30;
        }
      }
    }
    &:hover {
      .upload-ico {
        transform: translateY(-5px);
        svg {
          text {
            fill: $color--orange;
          }
        }
      }
    }
  }
}

.form-desc {
  p {
    float: left;
    font-size: 2rem;
    padding-top: 2rem;
  }

  .form-item {
    float: left;
    margin-left: 2rem;
  }
}

.multiselect {
  .dropdown {
    button {
      overflow: hidden;
      border: 0;
      background: transparent;
      float: left;
      width: auto;
      padding: 1rem;
      border-bottom: 2px solid $color--black;
      border-radius: 0;
      &:after {
        background: transparent url('../../images/select-arrow.svg') no-repeat
          right center;
        margin-left: -2px;
        border-bottom: 0;
        vertical-align: 0;
        border-top: 0;
        border-radius: 0;
        border-left: 0;
        padding: 8px;
        position: relative;
        top: 5px;
      }
    }
    .dropdown-menu {
      padding: 5px !important;
      border: solid 1px $color--black;
      border-left: 0;
      margin-top: 4.1rem;
      .btn-group {
        display: none;
      }
      .dropdown-item {
        float: left;
        .form-check {
          float: left;
          cursor: pointer;
          @include respond-to('l') {
            @include borderBottom-anim($color--black);
          }
          label {
            margin-left: 1rem;
            margin-top: 3px;
            position: relative;
            float: left;
            width: auto !important;

            font-size: 1.6rem;
            font-style: normal;
            color: $color--black;
          }
          input {
            margin-top: 1.1rem;
            margin-left: -1.25rem;
          }
        }
      }
    }
  }
}

// special checkbox
.orange-checkbox {
  position: relative;
  input {
    display: none;
    &:checked + label:before {
      background-color: $color--orange;
      border-color: $color--orange;
      color: #fff;
    }
    float: left;
    color: $color--white;
    &:checked + label:before {
      background-color: $color--white;
      color: $color--orange;
    }
  }
  input + label {
    display: block;
    margin: 0.2em;
    cursor: pointer;
    padding: 0.2em;
    &:before {
      content: '\2714';
      border: 0.1em solid #000;
      border-radius: 0.2em;
      display: inline-block;
      width: 1em;
      height: 1em;
      margin-right: 0.2em;
      vertical-align: bottom;
      color: transparent;
      transition: 0.2s;
    }
  }
  &:active:before {
    transform: scale(0);
  }

  label:not(.checkbox-label) {
    color: $color--black;
    cursor: text;
    left: 0;
    transition: all 0.3s ease;
    z-index: 3;
    font-size: 1.3rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    letter-spacing: normal;
    line-height: 0.8;
  }

  input:focus + label,
  .field-filled label {
    font-size: 1rem;
    font-weight: 300;
    top: -5px;
    z-index: 55;
  }
}

.multiselect {
  .dropdown {
    .dropdown-item {
      input {
        &.form-check-input {
          &:before {
            content: '';
            height: 100%;
            position: absolute;
            z-index: 99;
            width: 500px;
          }
        }
      }
    }
  }
}
