.footer {
  background-color: $color--blue;
  color: $color--white;
  font-size: 1.3rem;
  font-weight: 300;
  padding: 0 6.8rem 0 12.8rem;
  z-index: 30;
  .footer-logo {
    padding-left: 0;
    padding-top: 3rem;
  }

  .footer-text {
    font-size: 1.6rem;
    padding-bottom: 1.3rem;
    padding-top: 3.3rem;

    p {
      color: $color--white;

      a {
        border-bottom: 1px solid $color--white;
        color: $color--white;

        &:hover {
          border-bottom-color: $color--orange;
          color: $color--orange;
        }
      }
    }
  }

  .footer-menu {
    padding-bottom: 1.3rem;
    padding-top: 3.3rem;
    .menu-row {
      float: right;
      &:last-child {
        margin-right: 5rem;
      }
    }
    ul {
      float: left;
      padding: 0;

      li {
        float: left;
        list-style-type: none;

        &:marker {
          display: none;
        }

        a {
          border-bottom: 1px solid $color--white;
          color: $color--white;

          &:hover {
            border-bottom-color: $color--orange;
            color: $color--orange;
          }
        }

        &:nth-child(n) {
          margin-right: 1rem;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }

    .year {
      float: right;
      margin: 0;
    }
  }

  @media (max-width: 990px) {
    padding: 0 45px;
    .footer-text {
      padding-left: 0;
    }
  }
}
