@import 'var';
@import 'mixin';
@import 'responsive';

@import 'fonts';
@import 'atoms/atoms';
@import 'molecules/molecules';
@import 'organisms/organisms';

@import 'templates/templates';

html {
  font-size: 8px;
  @media (min-width: 1920px) {
    font-size: 10px;
  }
}

body {
  color: $color--black;
  font-family: 'MessinaSansWeb';
  font-feature-settings: 'liga' on; /* ligatures: on or off */
  font-feature-settings: 'dlig' on; /* discretionary-ligatures: on or off */
  font-size: 1.6rem;
}

.h-100-v {
  min-height: 100vh;
}
.lh-3 {
  line-height: 1.2em;
}
.header-logo {
  padding-left: 10rem;
  padding-top: 6.8rem;

  @include respond-to('m') {
    padding: 0;
    .logo {
      padding: 2rem;
    }
  }
}

.container-buttons {
  float: left;
  margin: 0;
  .back-btn,
  .next-btn {
    float: left;
    width: 50%;
    padding: 0;
    &.next-btn-sidebar {
      display: none;
    }
    @media (max-width: 765px) {
      &.next-btn-sidebar {
        display: block;
      }
    }
  }
  .next-btn {
    button.btn {
      border: 2px solid $color--orange;
    }
  }
  // @include respond-to('m') {
  //   margin-left: 15px;
  // }
}

body::-webkit-scrollbar {
  width: 0.7rem;
}

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
}

body::-webkit-scrollbar-thumb {
  background-color: rgb(204 120 40 / 61%);
  border-radius: 0.7rem;
}

.col {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
}

@media print {

  .zsiq_floatmain,
  #js-consent-banner {
    display:none!important;
  }

}
