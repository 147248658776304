.areaSpecification {
  .form {
    input[type='text'],
    input[type='number'] {
      width: 100%;
      min-width: 100%;
    }
  }
  .area-specification-info {
    position: absolute;
    right: 0;
    margin-top: -50px;
    padding: 0;
    .infobox-wrapper {
      float: right;
      svg {
        g {
          stroke: $color--orange;
        }
        path {
          fill: $color--orange;
        }
      }
    }
  }

  .area-rug-title {
    font-size: 2rem;
    font-weight: 300;
    margin-top: 4rem;
    margin-bottom: 1.9rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &.no-margin {
      margin-top: 0;
    }
  }

  .area-rug-delete {
    padding: 1rem;
    min-height: auto;
    height: auto;
    min-width: auto;
    width: auto;
    border-radius: 5px;
    line-height: 0;
    margin: 0;
  }

  .area-rug-add {
    appearance: none;
    margin: 0;
    padding: 0;
    line-height: 1;
    border: none;
    background-color: transparent;
    color: $color--orange;
    font-size: 1.6rem;
    font-weight: 300;
    display: flex;
    align-items: center;
    margin-top: 3.5rem;

    span {
      text-decoration: underline;
      margin-left: 1.8rem;
    }
  }
  // .carpet-radio-btn {
  //   label {
  //     opacity: 1;
  //     input[type='radio'] {
  //       left: -20px;
  //       top: 0;
  //     }
  //   }
  // }

  @include respond-to('m') {
    .form {
      margin-top: 2rem;
    }
  }
  @media (max-width: 1210px) {
    .form-item {
      label[for='ElseAreaSpecification'] {
        font-size: 16px;
      }
    }
  }
}
