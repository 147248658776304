.confirm-modal {
  .modal-dialog {
    max-height: 90%;
    max-width: 90%;

    .modal-content {
      background-color: $color--orange;
      color: $color--white;
      padding: 1.6rem;

      .modal-header {
        border-bottom: 0;
      }

      .modal-footer {
        margin: 0 auto;
        width: 90%;
        border: 0;
        padding: 0;

        button {
          background-color: $color--white;

          span {
            color: $color--orange;

            &::before {
              background-color: orange;
            }
          }

          &.disabled {
            background-color: $color--white;
            opacity: 0.2;

            span {
              color: $color--orange;
            }
          }
        }

        .no-permission {
          margin-top: -2rem;
          margin-right: 4rem;
          @include animation(0.3s);

          &:hover {
            transform: scale(1.02);
          }
        }
      }

      .modal-body {
        margin: 0 auto;
        max-width: 90%;
        min-width: 45rem;
        padding: 10rem 0;

        h1 {
          line-height: 1.18;
        }

        .card-upload {
          display: flex;
          cursor: pointer;
          .upload-part {
            align-items: flex-end;
            border-bottom: 2px solid;
            display: flex;
            float: left;
            justify-content: flex-end;
            margin-bottom: 3.5rem;
            position: relative;
            width: 100%;

            .upload-input-wrapper {
              width: 100%;
            }

            label {
              bottom: 0;
              cursor: pointer;
              font-size: 1.6rem;
              font-size: 2rem;
              left: 0;
              margin-top: 1rem;
              position: absolute;
              top: auto;
              width: 100%;

              &.active {
                bottom: 2.3rem;
                left: 0;
                position: absolute;
                top: auto;
                width: 100%;
              }
            }

            input:not([type='checkbox']) {
              appearance: initial;
              cursor: pointer;
              min-width: auto;
              opacity: 0;
              width: 100%;
              z-index: 99;
              position: absolute;
              bottom: 0;
            }

            p.file_name {
              bottom: -9px;
              cursor: pointer;
              height: 2.6rem;
              overflow: hidden;
              position: absolute;
              width: 100%;
            }

            svg {
              fill: $color--white;
              height: 7rem;
              padding: 1.8rem 0;
              position: relative;
              right: 0;
              top: 1rem;
              float: right;

              &.remove-ico {
                padding-top: 3.5rem;
                z-index: 30;
                float: right;
              }

              path {
                fill: $color--white;
                stroke: $color--white;
              }

              text {
                fill: $color--white;
              }
            }
            .read-permission {
              position: absolute;
              bottom: -5rem;
              width: 100%;
              height: 3rem;
              padding-top: 2rem;
              label {
                opacity: 1;
                font-size: 1.6rem;
                width: auto;
                position: relative;
                float: left;
                margin: 0;
                margin-left: 0;
                margin-top: -3px;
                color: $color--white;
                &:before {
                  position: relative;
                  top: -2px;
                  border-color: $color--white;
                  width: 15px;
                  height: 15px;
                  margin-right: 5px;
                  float: left;
                }
              }
              input {
                float: left;
                color: $color--white;
                &:checked + label:before {
                  background-color: $color--white;
                  border-color: $color--white;
                  color: $color--orange;
                }
              }
            }

            input:focus + label,
            .field-filled label {
              bottom: 2.3rem;
              font-size: 1.5rem;
              opacity: 0.8;
            }

            @media (min-width: 768px) {
              &:hover {
                .file_name {
                  color: $color--white;
                }

                .upload-part {
                  border-bottom: 2px solid $color--white;
                }

                input {
                  border-bottom: 2px solid $color--white;
                }

                svg {
                  fill: $color--white;

                  path {
                    fill: $color--white;
                    stroke: $color--white;
                  }

                  text {
                    fill: $color--white;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &.white-modal {
    color: $color--white;

    p,
    span {
      color: $color--white;
    }
  }

  &.blue-modal {
    color: $color--white;
    .modal-content {
      background-color: $color--blue;
      img {
        width: 100%;
      }
    }

    p,
    span {
      color: $color--white;
    }
    img {
      border-radius: 5px;
    }
  }

  @include respond-to('m') {
    .modal-dialog {
      max-width: 100%;
      .modal-content {
        padding: 0 1rem;
        button {
          .close-overlay-icon {
            right: 10px;
            top: 10px;
          }
        }
        .modal-body {
          padding-top: 0;
          min-width: 50%;
          margin: 0;
          max-width: 100%;
          margin-top: 3rem;
        }
        .modal-footer {
          max-width: 100%;
          padding: 0;
          justify-content: flex-start;
        }
      }
    }

    .modal-footer {
      display: block;
      min-width: 100%;
      margin: 0;
      .no-permission {
        float: left;
        margin: 0;
        margin-right: 5%;
      }
      .btn {
        float: right;
        margin: 0;
        margin-top: -30px;
        margin-bottom: 20px;
      }
    }
  }
}

@include respond-to('m') {
  .wall-to-wall-popup {
    .singleRow {
      margin-bottom: 50px;
    }
  }
}

@media (max-width: 380px) {
  .container-buttons {
    button {
      padding-right: 0;
      padding-left: 0;
      min-width: 120px;
    }
  }
}

.overlay-modal {
  &.fade {
    display: flex !important;
  }
  flex-flow: column;
  justify-content: center;
  .modal-dialog {
    max-height: 90%;
    max-width: 90%;

    .modal-content {
      background-color: $color--orange;
      border: 0;
      padding: 0rem 18rem 10rem 0rem;
      .modal-body {
        color: $color--white;

        margin: 0 auto;
        min-width: 45rem;
        padding: 10rem;

        h1 {
          line-height: 1.18;
        }
        .content {
          position: relative;
          .image-row {
            position: relative;
            margin-top: -20rem;
          }
          .extra-lines {
            a {
              &:before {
                background-color: $color--white;
              }
            }
          }
        }
      }
    }
    .footer-modal {
      padding-right: 10rem;
      button {
        float: right;
        span {
          color: $color--orange;
        }
      }
    }
  }

  &.white-modal {
    color: $color--white;

    p,
    span {
      color: $color--white;
    }
  }
  @media (max-width: 1000px) {
    &.white-modal {
      color: $color--white;

      p,
      span {
        color: $color--white;
      }
    }
    .modal-dialog {
      padding: 0;
      max-width: 100%;
      .modal-content {
        padding: 0;
        .modal-body {
          padding: 1rem;
          min-width: 100%;
          .content {
            h2 {
              font-size: 2rem;
            }
            .image-row {
              max-width: 70%;
              padding: 0 8px;
              margin: 0 auto;
              margin-bottom: 0 !important;
              margin-top: 3rem !important;
            }
            .contact-info-wrapper {
              margin-top: 0 !important;
              .contact-info {
                max-width: 70%;
                margin: 0 auto;
                margin-bottom: 3rem;
              }
            }
          }
        }
      }
      .footer-modal {
        padding-right: 0;
        padding: 0 20px;
        margin-bottom: 10px;
        button {
          float: left;
          width: 100%;
        }
      }
    }
  }
}
