.logo {
  cursor: pointer;
}

.picture-selection {
  @include animation(0.3s);
  cursor: pointer;
  padding: 0;
  position: relative;
  margin-bottom: 8rem;

  &::before {
    @include animation(0.3s);
    background-image: linear-gradient(to bottom, #fff, rgba(255, 255, 255, 0));
    content: '';
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    visibility: hidden;
    width: 100%;
  }

  img {
    border-radius: 5px;
    width: 100%;
  }

  .label-pic-selection {
    @include animation(0.3s);
    border-bottom: 2px solid;
    color: $color--black;
    float: left;
    font-size: 1.6rem;
    font-stretch: normal;
    font-style: normal;
    font-weight: 300;
    letter-spacing: 0.32px;
    line-height: 1.38;
    padding: 1rem 0;
    text-align: left;
    width: 100%;
  }

  .select-box {
    @include animation(0.3s);
    color: $color--orange;
    font-size: 3.2rem;
    height: 2rem;
    opacity: 0;
    position: absolute;
    right: 3rem;
    top: 5px;
    visibility: hidden;
    width: 2rem;
    z-index: 99;
    .number-select {
      border-radius: 50%;
      background-color: $color--orange;
      width: 4rem;
      height: 4rem;
      text-align: center;
      float: left;
      color: $color--white;
      font-size: 1.2rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-top: 3px;
      margin-right: -7px;
      float: right;
    }
  }

  &.selected {
    &::before {
      opacity: 0.7;
      visibility: visible;
    }

    .select-box {
      opacity: 1;
      visibility: visible;
    }

    .label-pic-selection {
      color: $color--orange;
    }
  }

  @media (min-width: 768px) {
    &:not(.disabled) {
      &:hover {
        &::before {
          opacity: 0.7;
          visibility: visible;
        }

        .select-box {
          opacity: 1;
          visibility: visible;
        }

        .label-pic-selection {
          color: $color--orange;
        }
      }
    }
  }

  &.disabled {
    cursor: not-allowed;
  }
}
