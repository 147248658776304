.sidebar-profile {
  padding: 10px 0;
  z-index: 30;
  position: fixed;
  overflow-x: scroll;
  height: 100vh;
  padding-bottom: 15rem;

  h4 {
    font-size: 2.5rem;

    .topline {
      font-size: 1.3rem;
    }
  }

  .phone-mail {
    p {
      float: left;
      margin-right: 1.8rem;
    }
    .link {
      font-weight: 300;
    }
  }

  .nextstep_wrapper {
    float: left;
    width: 100%;
    margin-top: 3rem;
    min-height:10rem;
    .bottom-buttons {
      // bottom: 100px;
      // position: fixed;
      // right: 0;
      // text-align: right;
      // width: auto;
      // z-index: 99;

      padding-right: 3rem;
      float: right;
      text-align: right;
      &.full-size {
        button {
          width: 100%;
        }
      }
    }
  }

  .selected-options {
    margin-bottom: 2rem;
    .price-calculations {
      float: left;
      position: relative;
      width: 100%;
      font-size: 1.6rem;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.75;
      letter-spacing: normal;
      text-align: left;
      margin-top: 10vh;
      ul {
        float: left;
        max-height: 22vh;
        padding-right: 1rem;
        width: 100%;
        overflow: auto;
        li {
          min-width: 100%;
          border-top: 1px solid $color--black;
          padding: 2.1rem 0px;
          margin-bottom: 0;
          .price {
            float: right;
          }
          &:last-child {
            border-bottom: 2px solid $color--black;
          }
          .extrainfo-product-price {
            float: left;
            width: 100%;
            font-size: 13px;
            font-weight: 300;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.54;
            letter-spacing: normal;
            text-align: left;
            color: #1f1d1a;
            padding-left: 2.3rem;
            padding-right: 5rem;
          }
        }
      }
      .total-price {
        float: right;
        padding-right: 1rem;
        .from {
          font-stretch: normal;
          font-style: normal;
          line-height: 1;
          letter-spacing: normal;
          text-align: right;
          margin-right: 2rem;
          float: left;

          font-size: 1.7rem;
          font-weight: 300;
          margin-top: 40px;
          line-height: 0;
        }
        .price {
          font-size: 4.8rem;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.67;
          letter-spacing: normal;
          text-align: right;
        }
        &.total-from {
          float: left;
          width: 100%;
          .from {
            float: left;
            margin-top: 4.5rem;
          }
          .price {
            float: right;
          }
          color: $color--orange;
          border-bottom: 2px solid $color--orange;
          .from {
            font-size: 1.6rem;
          }
        }
      }

      .shipping-info {
        float: left;
        width: 100%;
        margin-top: 20px;
        .infobox-wrapper {
          float: left;
          margin-right: 2rem;
          margin-top: 1.5rem;
          height: 7rem;
          svg {
            opacity: 0.4;
          }
        }
        .shipping-content-info {
          opacity: 0.5;
          font-size: 1.3rem;
          font-weight: 300;
          font-stretch: condensed;
          font-style: normal;
          letter-spacing: 2.08px;
          text-align: left;
          margin-top: 7px;
          line-height: 1.18;
          text-transform: uppercase;
          @media (max-width: 1000px) {
            margin-bottom: 30px;
          }
        }
      }
    }
  }
}

.sidebar-profile .selected-options .price-calculations ul::-webkit-scrollbar {
  width: 0.7rem;
}

.sidebar-profile
  .selected-options
  .price-calculations
  ul::-webkit-scrollbar-track {
  box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
}

.sidebar-profile
  .selected-options
  .price-calculations
  ul::-webkit-scrollbar-thumb {
  background-color: rgb(204 120 40 / 61%);
  border-radius: 0.7rem;
}
.currency-selector {
  clear: both;
  float: right;
  width: auto;
  margin: 0;
  padding: 0;
  padding-right: 1rem;
  select {
    float: left;
    width: auto;
    // margin-top: 20px;
    min-width: 40px;
    font-weight: 400;
  }
}
