.row-card {
  @include animation(0.3s);
  border: solid 1px $color--black;
  border-radius: 5px;
  cursor: pointer;
  float: left;
  height: 28.4rem;
  padding: 3.9rem 4.4rem 3.9rem 3.9rem;
  // min-width: 30.4rem;
  width: 100%;
  margin-bottom: 3rem;
  margin-right: 1rem;

  @media (max-width: 1450px) {
    padding: 1.9rem 0.4rem 1.9rem 0.9rem;
  }

  .circular {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    vertical-align: middle;
    span {
      width: 100%;
    }
    @include respond-to('m') {
      margin: 0;
    }
  }

  @media (min-width: 768px) {
    &:hover {
      background: $color--orange;
      border-color: $color--orange;

      h4,
      span,
      label {
        color: $color--white;
      }

      .circular {
        background: $color--white;
        span {
          svg {
            width: 100%;
          }
        }
      }
    }
  }

  .header {
    height: 70%;
  }

  &.card-upload {
    cursor: pointer;
    .header {
      &:before {
        content: '';
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 222;
      }
    }

    .upload-part {
      border-bottom: 2px solid;
      cursor: pointer;
      float: left;
      position: relative;
      width: 100%;

      label {
        cursor: pointer;
        font-size: 1.6rem;
        margin-top: 1rem;

        &.active {
          font-size: 1.2rem;
          margin-top: -5px;
          top: 2rem;
        }
      }

      input {
        appearance: initial;
        cursor: pointer;
        min-width: auto;
        opacity: 0;
        width: 100%;
      }

      p.file_name {
        bottom: -9px;
        cursor: pointer;
        height: 2.6rem;
        overflow: hidden;
        position: absolute;
        width: 100%;
      }

      svg {
        height: 7rem;
        padding: 1.4rem 0;
        position: absolute;
        right: 0;
        top: -5px;

        &.remove-ico {
          padding-top: 3.5rem;
          z-index: 30;
        }
      }
    }

    input:focus + label,
    .field-filled label {
      opacity: 0.8;
      top: 0;
    }

    @media (min-width: 768px) {
      &:hover {
        .file_name {
          color: $color--white;
        }

        .upload-part {
          border-bottom: 2px solid $color--white;
        }

        input {
          border-bottom: 2px solid $color--white;
        }

        svg {
          fill: $color--white;

          path {
            fill: $color--white;
            stroke: $color--white;
          }

          text {
            fill: $color--white;
          }
        }
      }
    }
  }

  @include respond-to('m') {
    padding: 1rem;
    max-height: 20rem;
    padding-bottom: 5rem;
    margin-right: 0;
    margin-left: 0;
    .header {
      height: 12rem;
      h4 {
        font-size: 2rem;
      }
    }
  }
}
