.order-template-completion {
  overflow: hidden;
  .pagerow {
    margin-top: 10rem;
  }
  .headline-row {
    display: none;
  }
  .design-selection {
    .chosen-designs-row {
      img {
        width: 100%;
      }
      .color-selection {
        border-radius: 5px;
        min-height: 450px;
        @media (max-width: 760px) {
          min-height: 150px;
        }
      }
      &:first-child {
        border-top: 0;
      }
      .edit-btn {
        display: none;
      }
    }
  }
  .print-icon {
    max-width: 5rem;
    min-width: 1rem;
    position: absolute;
    right: -30rem;
    top: 0;
    svg {
      max-width: 100%;
      height: auto;
      cursor: pointer;
      g {
        path {
          @include animation(0.3s);
        }
      }
      &:hover {
        g {
          path {
            fill: $color--orange;
          }
        }
      }
    }
  }
}
