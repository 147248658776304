.cls-1 {
  fill: none;
  stroke: #cc7828;
  stroke-linecap: round;
  stroke-width: 2px;
}
.close-overlay-icon {
  position: absolute;
  right: 3.2rem;
  top: 3.2rem;
  width: 3.2rem;
  height: 3.2rem;
  cursor: pointer;
  z-index: 33;
  @include animation(0.3s);
  &:before,
  &:after {
    position: absolute;
    left: 15px;
    content: ' ';
    height: 33px;
    width: 2px;
    background-color: $color--white;
    @include animation(0.3s);
  }
  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }
  &:hover {
    opacity: 0.3;
    &:before,
    &:after {
      background-color: $color--danger;
    }
  }
}
