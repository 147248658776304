h1 {
  font-size: 9.5rem;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 1.18;
  margin: 1.9rem 0 0;
  text-align: left;

  @include respond-to('m') {
    font-size: 4.8rem;
    line-height: 1;
    margin: 1.5rem 0 0;
    padding: 0;
  }
}

h2 {
  font-size: 7.6rem;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 1.18;
  margin: 0.5rem 0 0;
  text-align: left;

  @include respond-to('m') {
    font-size: 3.1rem;
    line-height: 1.26;
  }
}

h3 {
  font-size: 4.8rem;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 1.27;
  margin: 1rem 0 0;
  text-align: left;

  @include respond-to('m') {
    font-size: 3.1rem;
    line-height: 1.26;
  }
}

h4 {
  font-size: 3.1rem;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 1.26;
  text-align: left;

  @include respond-to('m') {
    font-size: 2.5rem;
    line-height: 1.24;
  }
}

h1,
h2,
h3,
h4,
.cp-l {
  font-feature-settings: 'kern' 1; /* turn on kerning, highly recomened */
  font-feature-settings: 'kern' 1; /* turn on kerning, highly recomened */
  font-feature-settings: 'kern' 1; /* turn on kerning, highly recomened */
  font-feature-settings: 'kern' 1; /* turn on kerning, highly recomened */
  font-feature-settings: 'kern' 1; /* turn on kerning, highly recomened */

  font-feature-settings: 'liga' on; /* ligatures: on or off */
  font-feature-settings: 'dlig' on; /* discretionary-ligatures: on or off */
  font-feature-settings: 'tnum' on; /* tabular figures: on or off */
  font-feature-settings: 'onum' on; /* old-style-figures: on or off */
  font-feature-settings: 'ss01' off; /* alternate glyphs (stylistic Set): on or off */
  font-kerning: normal; /* turn on kerning, highly recomened */
  -webkit-font-smoothing: antialiased; /* apply font anti-aliasing */
  -moz-osx-font-smoothing: grayscale; /* optimize font rendering */
  text-rendering: optimizeLegibility; /* emphasizes in legibility over rendering speed */
  .topline {
    color: $color--black;
    font-size: 1.6rem;
    font-stretch: condensed;
    font-style: normal;
    font-weight: 300;
    letter-spacing: 2.56px;
    line-height: 1.75;
    opacity: 0.5;
    text-align: left;
  }
}

.topline {
  color: $color--black;
  float: left;
  font-size: 1.6rem;
  font-stretch: condensed;
  font-style: normal;
  font-weight: 300;
  letter-spacing: 2.56px;
  line-height: 1.75;
  opacity: 0.5;
  text-align: left;
  text-transform: uppercase;
  width: 100%;
  min-height: 30px;
}

.subline {
  color: $color--black;
  font-size: 2rem;
  font-stretch: normal;
  font-style: normal;
  font-weight: 300;
  letter-spacing: normal;
  line-height: 1.75;
  margin: 1rem 0 0;
  text-align: left;

  @include respond-to('m') {
    color: $color--black;
    font-size: 1.6rem;
    font-stretch: normal;
    font-style: normal;
    font-weight: 300;
    letter-spacing: normal;
    line-height: 1.56;
    margin: 1rem 0 0;
    text-align: left;
  }
}

.cp-l {
  color: $color--black;
  font-size: 2.5rem;
  font-stretch: normal;
  font-style: normal;
  font-weight: 300;
  letter-spacing: normal;
  line-height: 1.92;
  text-align: left;
}

.cp-m {
  color: $color--black;
  font-size: 2rem;
  font-stretch: normal;
  font-style: normal;
  font-weight: 300;
  letter-spacing: normal;
  line-height: 1.75;
  text-align: left;

  .topline {
    color: $color--black;
    font-size: 1.3rem;
    font-stretch: condensed;
    font-style: normal;
    font-weight: 300;
    letter-spacing: 2.08px;
    line-height: 1;
    text-align: left;
  }
}

.cp-s {
  color: $color--black;
  font-size: 1.6rem;
  font-stretch: normal;
  font-style: normal;
  font-weight: 300;
  letter-spacing: normal;
  line-height: 1.75;
  text-align: left;

  .topline {
    color: $color--black;
    font-size: 1.3rem;
    font-stretch: condensed;
    font-style: normal;
    font-weight: 300;
    letter-spacing: 2.08px;
    line-height: 1;
    text-align: left;
  }
}

// links
a.link {
  color: $color--orange;
  float: left;
  font-size: 1.6rem;
  letter-spacing: 0.32px;
  line-height: 1.38;
  position: relative;
  text-align: left;
  text-decoration: none;

  @include respond-to('l') {
    @include link-anim($color--orange);
  }

  @include respond-to('s') {
    font-size: 1.3rem;
    font-stretch: normal;
    font-style: normal;
    font-weight: 300;
    letter-spacing: 0.33px;
    line-height: 1.31;
    margin: 0 0 2.5px;
    text-align: left;

    &::before {
      background-color: #cc7828;
      bottom: 0;
      content: '';
      height: 1px;
      left: 0%;
      position: absolute;
      visibility: visible;
      width: 100%;
    }
  }

  &:hover,
  &:active,
  &:not(:disabled):not(.disabled):active {
    color: $color--orange;
    text-decoration: none;
  }

  &:focus {
    outline-color: transparent;
  }
}

a {
  @include animation(0.3s);

  &:focus,
  &:hover {
    text-decoration: none;
  }
}

hr {
  border-top: 1px solid $color--black;
}

.underline {
  border-bottom: 1px solid $color--white;
}

.cursor {
  cursor: pointer;
}

.f-s-20 {
  font-size: 2rem;
}

.bold {
  font-weight: 900;
}

// .topline.empty {
//   display: none;
// }

.condensed {
  font-family: 'MessinaSansWebCondensed';
}
