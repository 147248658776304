/* eslint-disable */
#js-consent-banner {
  bottom: -20px;
  color: $color--white;
  height: 0;
  max-width: 600px;
  padding: 20px;
  position: fixed;
  right: 0;
  z-index: 103;

  &.is-active {
    .consent-banner__content {
      transform: translateY(-100%);
    }
  }

  .consent-banner__texts {
    margin-bottom: 10px;

    p {
      font-family: 'MessinaSansWeb';
      font-size: 2rem;
      font-weight: 300;
      line-height: 1.5;
    }
  }

  label {
    cursor: pointer;
    display: inline-block;
    font-weight: 300;

    span {
      position: relative;
      top: -5px;
    }
  }

  input[type='checkbox'] {
    cursor: pointer;
    display: none;
  }

  input[type='checkbox'] + label {
    cursor: pointer;
    font-size: 2rem;
    margin-top: 1rem;
    position: relative;
  }

  input[type='checkbox'] + label::before {
    background-color: transparent;
    border: 1px solid $color--white;
    border-radius: 5px;
    content: '';
    cursor: pointer;
    display: block;
    float: left;
    height: 2rem;
    margin-right: 0.5rem;
    width: 2rem;
  }

  input[type='checkbox']:checked+label::after {
    border-bottom: 3px solid $color--white;
    border-radius: 2px;
    border-right: 3px solid $color--white;
    content: '';
    display: inline-block;
    height: 1.3rem;
    left: 0.5rem;
    position: absolute;
    top: 0.2rem;
    transform: rotate(45deg);
    width: 0.9rem;
  }

  input[type='checkbox'] {
    background: none !important;
    border: 1px solid $color--white !important;
    border-radius: 2px;
  }

  a {
    text-decoration: underline;
  }
}

.consent-banner__content {
  padding-bottom: 4rem;
  padding-top: 4rem;
  position: relative;
  transform: translateY(10%);

  &::before {
    background-color: $color--orange;
    content: '';
    height: 100%;
    left: -25vw;
    position: absolute;
    top: 0;
    width: 150vw;
    z-index: -1;
  }
}

.consent-banner__buttons {
  display: flex;
  justify-content: space-between;
  padding-top: 2.4rem;

  .text {
    display: inline-block;
  }

  a {
    color: $color--white;
    cursor: pointer;
    font-size: 2rem;
    font-weight: 300;
    text-decoration: underline;
  }
}

.consent-banner__bt-reject,
.consent-banner__buttons a {
  cursor: pointer;
  text-decoration: underline;
}

@media only screen and (min-width: 768px) {
  .consent-banner__content {
    background-color: $color--orange;
    padding-right: 10%;
    position: relative;

    &::before {
      left: -10%;
      width: 180vw;
    }
  }
}
