.checkout-page {
  .form {
    min-width: 100%;
  }
  .rows {
    ul {
      padding: 0;
      margin-top: 6rem;
      li {
        list-style-type: none;
        font-size: 2rem;
        font-weight: 300;
        line-height: 1.35;
        text-align: left;
        border-bottom: solid 1px $color--black;
        padding: 40px 0;
        position: relative;
        .result {
          font-size: 3.8rem;
          margin: 0 1rem;
          font-weight: normal;
          line-height: 1.55;
          text-align: right;
        }
        .edit-btn {
          font-size: 1.6rem;
          font-weight: 300;
          line-height: 0.63;
          color: $color--orange;
          text-align: right;
          position: absolute;
          right: 1rem;
          top: 0;
          background: transparent;
          border: 0;
          border-bottom: solid 1px $color--orange;
          padding: 0px 0 5px 0;
          margin-top: 5rem;
          cursor: pointer;

          &:focus {
            border-bottom: solid 2px $color--orange;
            background: transparent;
            outline-color: transparent;
          }
          &:hover {
            border-bottom: solid 2px $color--orange;
          }
        }

        &:first-child {
          border-top: solid 1px $color--black;
        }
      }
    }
  }

  .chosen-products {
    .row {
      .product-image {
        img {
          border-radius: 5px;
          width: 100%;
        }
      }
      ul {
        margin-top: 2rem;
        border-top: 2px solid;
        li {
          list-style-type: none;
          padding: 2rem 0;
          .quantity {
            margin-right: 3rem;
          }
          .price {
            float: right;
          }
        }
      }
    }
    .chosen-product-row {
      margin-bottom: 6rem;
    }
  }
  @include respond-to('m') {
    .rows {
      ul {
        li {
          .result {
            font-size: 1em;
            font-weight: 900;
          }
          .edit-btn {
            margin-top: 10px;
          }
        }
      }
    }
  }
}
