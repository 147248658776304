.colorcontent-box {
  background-color: $color--orange;
  color: $color--white;
  padding: 6.1rem 20rem 6rem 5rem;
  border-radius: 5px;
  color: $color--white;
  .content {
    font-size: 2.2rem;
    font-weight: 300;
    line-height: 1.75;
    text-align: left;
  }
  .topline {
    color: $color--white;
  }
  .text {
    margin-bottom: 5rem;
  }

  @media (max-width: 1200px) {
    padding-left: 0;
    padding-right: 0;
    h2.text {
      font-size: 3rem;
    }
    .content {
      max-width: 100% !important;
    }
  }
}
