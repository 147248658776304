.chosen-designs {
  .row {
    .product-image {
      img {
        border-radius: 5px;
      }
    }
    ul {
      margin-top: 2rem;
      border-top: 2px solid;
      li {
        list-style-type: none;
        padding: 2rem 0;
        .quantity {
          margin-right: 3rem;
        }
        .price {
          float: right;
        }
      }
    }
  }
  .design-selection {
    .chosen-designs-row {
      border-bottom: 2px solid;
      padding: 6.1rem 0;
      img {
        width: 100%;
      }
      .color-selection {
        border-radius: 5px;
        min-height: 450px;
        @media (max-width: 760px) {
          min-height: 150px;
        }
      }
      .edit-btn {
        font-size: 1.6rem;
        font-weight: 300;
        line-height: 0.63;
        color: $color--orange;
        text-align: right;
        position: absolute;
        right: 1rem;
        top: 0;
        background: transparent;
        border: 0;
        border-bottom: solid 1px $color--orange;
        padding: 0px 0 5px 0;
        margin-top: 5rem;
        cursor: pointer;

        &:focus {
          border-bottom: solid 2px $color--orange;
          background: transparent;
          outline-color: transparent;
        }
        &:hover {
          border-bottom: solid 2px $color--orange;
        }
      }
      &:first-child {
        border-top: 2px solid;
      }
    }
  }
}
