.Product-container {
  .ProductSelectionRow {
    .product-selection {
      @include animation(0.3s);
      cursor: pointer;
      padding: 0;
      position: relative;

      img {
        border-radius: 5px;
        width: 100%;
      }

      .label-pic-selection {
        @include animation(0.3s);
        font-size: 3.1rem;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.26;
        letter-spacing: normal;
        text-align: left;
        color: $color--black;
        margin-top: 1.9rem;
        min-height: 8rem;
        float: left;
        width: 100%;
        .infobox-wrapper {
          float: right;
          svg {
            g {
              stroke: $color--orange;
            }
            path {
              fill: $color--orange;
            }
          }
        }
        .btn {
          padding: 0;
          min-width: auto;
          float: right;
          position: absolute;
          right: 0;
        }
      }

      .select-box {
        @include animation(0.3s);
        color: $color--orange;
        font-size: 3.2rem;
        height: 2rem;
        opacity: 0;
        position: absolute;
        right: 3rem;
        top: 5px;
        visibility: hidden;
        width: 2rem;
        z-index: 99;
      }

      .price_rows {
        padding: 0;
        float: left;
        width: 100%;
        border-bottom: 1px solid;
        padding-bottom: 1rem;
        li {
          float: left;
          width: 50%;
          list-style-type: none;
          .name_row {
            float: left;
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            height: 25px;
            .name {
              float: left;
              opacity: 0.5;
              font-size: 1.3rem;
              text-transform: uppercase;
              font-weight: 300;
              font-stretch: condensed;
              font-style: normal;
              line-height: 1;
              letter-spacing: 2.08px;
              text-align: left;
              margin-bottom: 5px;
              padding-right: 1rem;
            }
            .info {
              margin-left: 5px;
              float: left;
            }
          }
          .price {
            float: left;
            width: 100%;
            margin-bottom: 1.5rem;
            label {
              cursor: pointer;
              padding-left: 0;
              font-size: 1.6rem;
              &:before {
                margin-top: -2px;
                float: left;
              }
            }
            input {
              float: left;
              margin-right: 2rem;
              margin-top: 5px;
            }
            &.row-checked {
              label {
                color: $color--orange;
              }
            }
          }
        }
      }

      .infobox-wrapper {
        .hovered-information {
          font-size: 1.2rem;
          min-width: 15rem;
          text-align: center;
          margin-left: 3.5rem;
          margin-top: 2rem;
        }
      }

      &.selected,
      &:hover {
        &::before {
          opacity: 0.7;
          visibility: visible;
        }

        .select-box {
          opacity: 1;
          visibility: visible;
        }

        .label-pic-selection {
          color: $color--orange;
          .label {
            max-width: 85%;
            float: left;
            @media (max-width: 1200px) {
              font-size: 2.5rem;
            }
          }
        }
      }
    }
  }
}

.product-modal {
  .modal-dialog {
    .modal-content {
      .modal-footer {
        button {
          background-color: transparent;
          @include button-anim($color--white);
          span {
            color: $color--white;
          }
          &:hover {
            border: 2px solid $color--white;
            background: transparent;
          }
        }
      }
    }
  }
}
