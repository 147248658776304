.billingaddress-container {
  .form {
    input {
      // min-width: 100%;
      min-width: 37rem;
      box-sizing: border-box;
      @media (max-width: 1400px) {
        min-width: 100%;
      }
    }
    @media (max-width: 768px) {
      .form-item {
        min-width: 100%;
        input {
          min-width: 100%;
        }
      }
    }
  }
}
