@import 'start-page';
@import 'AreaSpecification';
@import 'AreaSpecificationDetails';
@import 'Checkout';
@import 'BillingAddress';
@import 'ColorSelective';
@import 'ChosenDesigns';
@import 'UploadInspiration';
@import 'ChoiceRole';
@import 'OrderCompletion';

.designsupport-row {
  @media (max-width: 1350px) {
    .col-lg-3 {
      min-width: 33.333%;
    }
  }
}

.hide-topline {
  .topline {
    display: none;
  }
}
