.info-banner {
  border-radius: 5px;
  background-color: $color--orange;
  padding: 3rem;
  float: left;
  width: 100%;
  .icon {
    float: left;
    margin-right: 2rem;
    svg {
      opacity: 0.4;
      path {
        fill: $color--white;
      }
      g {
        stroke: $color--white;
      }
    }
  }
  .content {
    float: left;
    .header {
      opacity: 0.5;
      font-size: 1.6rem;
      font-weight: 300;
      font-stretch: condensed;
      font-style: normal;
      line-height: 1;
      letter-spacing: 2.56px;
      text-align: left;
      color: $color--white;
      float: left;
      width: 100%;
    }
    .content-inner {
      font-size: 2rem;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: 2.2;
      letter-spacing: normal;
      text-align: left;
      color: $color--white;
      float: left;
      width: 100%;
    }
  }
  @include respond-to('m') {
    .icon {
      margin-bottom: 20px;
    }
  }
}
