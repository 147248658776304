.area-specification-details {
  .infobanner {
    margin-left: -4%;
    width: 102%;
    @media (max-width: 1400px) {
      .icon {
        margin-bottom: 20px;
      }
    }
  }
  .form {
    .key {
      font-size: 2rem;
    }
    select,
    input {
      min-width: 43rem;
    }
  }
}
