.btn {
  border-radius: 4rem;
  font-size: 1.6rem;
  min-width: 21rem;
  padding: 1.7rem 3.8rem 1.7rem 3.8rem;
  text-align: center;
  margin-bottom: 3rem;
  @include respond-to('s') {
    border: solid 2px $color--orange;
    border-radius: 4rem;
    padding: 1.2rem 1.7rem 1.2rem 2rem;

    .anim {
      &::before {
        display: none;
      }
    }
  }

  .anim {
    width: 100%;
  }

  &.btn-primary {
    @include button-anim($color--white);
    background-color: $color--orange;
    border: 0;
    color: $color--white;

    &:hover,
    &:active,
    &:not(:disabled):not(.disabled):active {
      background-color: $color--orange;
      border-color: transparent;
    }

    &:focus {
      outline-color: transparent;
    }
  }

  &.btn-white {
    @include button-anim($color--orange);
    background-color: $color--white;
    border: 2px solid $color--white;
    color: $color--orange;

    &:hover,
    &:active,
    &:not(:disabled):not(.disabled):active {
      background-color: $color--white;
    }

    &:focus {
      outline-color: transparent;
    }
  }

  &.btn-ghost {
    @include button-anim($color--orange);
    background-color: transparent;
    border: 2px solid $color--orange;
    color: $color--orange;

    &:hover,
    &:active,
    &:not(:disabled):not(.disabled):active {
      background-color: transparent;
    }

    &:focus {
      outline-color: transparent;
    }
  }

  &.btn-transparent-ghost {
    border: 2px solid $color--white;
  }

  &:focus {
    box-shadow: none;
    outline-color: transparent;
  }

  &.btn-transparent {
    background: transparent;
    outline-color: transparent;
    border: 0;
  }
}

.btn-empty {
  background: transparent;
  border: 0;
  outline-color: transparent;
}

.btn-small {
  min-width: 10rem;
  padding: 1.2rem 2rem;
  font-size: 1.2rem;
}

.circular {
  @include animation(0.3s);
  background-color: $color--orange;
  border: 2px solid $color--orange;
  border-radius: 2.5rem;
  color: $color--white;
  height: 5rem;
  margin: 1rem;
  width: 5rem;

  svg {
    font-size: 2rem;
  }

  &.blank {
    background-color: $color--white;
    border: solid 2px $color--orange;
    border-radius: 2.5rem;
    color: $color--orange;
  }

  &:hover {
    background-color: $color--white;
    color: $color--orange;

    &.blank {
      background-color: $color--orange;
      color: $color--white;
    }
  }

  &:focus {
    outline-color: transparent;
  }
}
